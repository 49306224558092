/* Slider Interface Css Started */

.slider-interface {
    margin-bottom: 5%;
}

.slider-interface .swiper .interface-card {
    padding: 0px 17px;
    border-right: 1px solid #5247d1;
    height: 68px;
    overflow: hidden;
    transition: height 0.3s ease;
}

.slider-interface .swiper:hover .interface-card {
   
    padding: 0px 21px;
    height: 166px;
    border-right: 1px solid #5247d1;
}

.slider-interface .swiper .interface-card .heading-holder h2 {
    font-size: 18px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.slider-interface .swiper:hover .interface-card .fa-icon {
    color: #5247d1;
}

.slider-interface .swiper .interface-card .text-holder p {
    /* font-size: 15px; */
    font-weight: 500;
    display: none;
    transition: opacity 0.3s ease;
    
}

.slider-interface .swiper:hover .interface-card .text-holder p {
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 6;
}

.slider-interface .swiper-pagination-bullet {
    width: 30px;
    height: 4px;
    background: #c3beff 0% 0% no-repeat padding-box;
    border-radius: 50px;
}

.slider-interface .swiper-pagination-bullet-active {
    width: 48px;
    background: #5247d1 0% 0% no-repeat padding-box;
    border-radius: 50px;
}

.slider-interface .swiper {
    padding-bottom: 50px;
    cursor: pointer;
}

/* Slider Interface Css End */

/* Media Query Started */

@media (min-width: 0px) and (max-width: 575px) {
    .slider-interface .swiper .interface-card {
        margin-right: -7px;
    }

    .slider-interface .swiper .interface-card .heading-holder h2 {
        font-size: 16px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .slider-interface .swiper:hover .interface-card {
        height: 190px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .slider-interface .swiper:hover .interface-card {
        height: 190px;
    }
}

.Download_App {
  height: 739px;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent linear-gradient(0deg, #F2F2F2 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  margin-bottom: 8%;
}

.Download_App .Download_App_content_holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #9d39d236;
  border-radius: 10px;
  padding: 10px 10px;
  width: 80%;
  height: 535px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Download_App .Download_App_content_holder .main-img-holder {
  position: relative;
}

.Download_App .Download_App_content_holder .main-img-holder .overlay-img-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Download_App .Download_App_content_holder .main-img-holder .bg-img-holder .mobile_bg_img {
  width: 100%;
  height: 541px;
  object-fit: contain;
}

.Download_App .Download_App_content_holder .main-img-holder .overlay-img-holder .img-holder .mobile_img {
  width: 100%;
  height: 350px;
  object-fit: contain;
}

.Download_App .Download_App_content_holder:hover .single_mobile {
  display: block;
}

.Download_App .Download_App_content_holder .main-img-holder .overlay-img-holder .grp_mobile {
  display: none;
}

.Download_App .Download_App_content_holder:hover .grp_mobile {
  display: block !important;
}

.Download_App .Download_App_content_holder:hover .single_mobile {
  display: none !important;
}

.Download_App .Download_App_content_holder .text_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* padding: 0px 0px 0px 70px; */
  overflow: hidden;
}

.Download_App .Download_App_content_holder .text_content .main_heading_holder .firstText {
  text-align: left;
  color: #333;
  font-size: 40px;
  font-weight: 900;
  font-family: "NakedPowerW00-Heavy" !important;
  opacity: 1;
  -webkit-text-stroke-color: currentColor;
  -webkit-text-stroke-width: 0.012em;
  -webkit-text-stroke: 1px #333;
  -webkit-text-fill-color: transparent;
  display: block;
  margin-left: -345px;
}

.Download_App .Download_App_content_holder .text_content .main_heading_holder .secondText {
  text-align: left;
  color: #7a4094;
  font-size: 40px;
  font-weight: 900;
  font-family: "NakedPowerW00-Heavy" !important;
  opacity: 1;
  display: none;
  margin-left: -345px;
}

.Download_App .Download_App_content_holder .text_content .subheading_holder h2 {
  text-align: left;
  font-size: 33px;
  font-family: "NakedPowerW00-Heavy" !important;
  color: #000;
  font-weight: 900;
}

.Download_App .Download_App_content_holder .text_content .get_app_holder {
  display: flex;
  margin-top: 10%;
}

.Download_App .Download_App_content_holder .text_content .get_app_holder .img-holder {
  margin: 0px 20px 0px 0px;
}

.Download_App .Download_App_content_holder .text_content .get_app_holder .img-holder .app_img {
  width: 100%;
  height: 55px;
  object-fit: contain;
  border-radius: 15px;
}

.Download_App .Download_App_content_holder .text_content .subheading_holder {
  margin-top: 10%;
}

/* Download_App_content_holder main zoom in out animation start */
.Download_App .scale-up-center {
  width: 100%;
  height: 676px;
  transition: 0.4s;
  animation: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.Download_App .scale-down-center {
  width: 80%;
  height: 535px;
  transition: 0.4s;
  -webkit-animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Download_App_content_holder main zoom in out animation end */

.Download_App .Download_App_content_holder:hover .secondText {
  display: block !important;
}

.Download_App .Download_App_content_holder:hover .firstText {
  display: none !important;
}

/*  main_heading_holder animation start */
.Download_App .Download_App_content_holder .text_content .main_heading_holder .slide-right {
  transition: 0.5s;
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(350px);
    transform: translateX(350px);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(350px);
    transform: translateX(350px);
  }
}

.Download_App .Download_App_content_holder .text_content .main_heading_holder .slide-left {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(350px);
    transform: translateX(350px);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(350px);
    transform: translateX(350px);
  }
}

/*  main_heading_holder animation end */

/* mobile-img-holder animation start */

.Download_App .Download_App_content_holder:hover .rotate-center {
  -webkit-animation: rotate-center 0.2s ease-in-out both;
  animation: rotate-center 0.2s ease-in-out both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.Download_App .Download_App_content_holder .overlay-img-holder .rotate-center-Reverse {
  -webkit-animation: rotate-center-Reverse 0.2s ease-in-out both;
  animation: rotate-center-Reverse 0.2s ease-in-out both;
}

@-webkit-keyframes rotate-center-Reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-webkit-animation);
  }
}

@keyframes rotate-center-Reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

/* mobile-img-holder animation start */

/* mobile bg img  animation start */
.Download_App .Download_App_content_holder:hover .rotate-center-bg {
  -webkit-animation: rotate-center-bg 0.5s ease-in-out both;
  animation: rotate-center-bg 0.5s ease-in-out both;
}

@-webkit-keyframes rotate-center-bg {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

@keyframes rotate-center-bg {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

.Download_App .Download_App_content_holder .main-img-holder .rotate-center-bg-Reverse {
  -webkit-animation: rotate-center-bg-Reverse 0.5s ease-in-out both;
  animation: rotate-center-bg-Reverse 0.5s ease-in-out both;
}

@-webkit-keyframes rotate-center-bg-Reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
}

@keyframes rotate-center-bg-Reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
}

/* mobile bg img animation end */

/* get_app_holder animation start */

.Download_App .Download_App_content_holder:hover .scale-up-tl {
  -webkit-animation: scale-up-tl 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-tl 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-tl {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

@keyframes scale-up-tl {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

.Download_App .Download_App_content_holder .get_app_holder .scale-down-tl {
  -webkit-animation: scale-down-tl 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-down-tl 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-down-tl {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

@keyframes scale-down-tl {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

/* get_app_holder animation end */

@media (min-width: 280px) and (max-width: 320px) {
  .Download_App .scale-down-center {
    height: 600px;
  }

  .Download_App .Download_App_content_holder .main-img-holder .bg-img-holder .mobile_bg_img {
    height: 300px;
  }

  .Download_App .Download_App_content_holder .main-img-holder .overlay-img-holder .img-holder .mobile_img {
    height: 200px;
  }

  .Download_App .Download_App_content_holder .text_content .subheading_holder h2 {
    font-size: 15px;
  }

  .Download_App .Download_App_content_holder .text_content .main_heading_holder .firstText {
    font-size: 17px;
  }

  .Download_App .Download_App_content_holder .text_content .main_heading_holder .secondText {
    font-size: 17px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Download_App .scale-down-center {
    height: 600px;
  }

  .Download_App .Download_App_content_holder .main-img-holder .bg-img-holder .mobile_bg_img {
    height: 300px;
  }

  .Download_App .Download_App_content_holder .main-img-holder .overlay-img-holder .img-holder .mobile_img {
    height: 200px;
  }

  .Download_App .Download_App_content_holder .text_content .subheading_holder h2 {
    font-size: 25px;
  }

  .Download_App .Download_App_content_holder .text_content .main_heading_holder .firstText {
    font-size: 22px;
  }

  .Download_App .Download_App_content_holder .text_content .main_heading_holder .secondText {
    font-size: 22px;
  }
}

@media (min-width: 486px) and (max-width: 765px) {
  .Download_App .scale-down-center {
    height: 600px;
  }

  .Download_App .Download_App_content_holder .main-img-holder .bg-img-holder .mobile_bg_img {
    height: 300px;
  }

  .Download_App .Download_App_content_holder .main-img-holder .overlay-img-holder .img-holder .mobile_img {
    height: 200px;
  }

  .Download_App .Download_App_content_holder .text_content .subheading_holder h2 {
    font-size: 25px;
  }

  .Download_App .Download_App_content_holder .text_content .main_heading_holder .firstText {
    font-size: 22px;
  }

  .Download_App .Download_App_content_holder .text_content .main_heading_holder .secondText {
    font-size: 22px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .Download_App .scale-up-center {
    height: 500px;
  }

  .Download_App .Download_App_content_holder .text_content .main_heading_holder .firstText {
    font-size: 25px;
  }

  .Download_App .Download_App_content_holder .text_content .main_heading_holder .secondText {
    font-size: 25px;
  }

  .Download_App .Download_App_content_holder .text_content .subheading_holder h2 {
    font-size: 25px;
  }

  .Download_App .Download_App_content_holder .main-img-holder .overlay-img-holder .img-holder .mobile_img {
    height: 220px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Download_App .Download_App_content_holder .text_content .main_heading_holder .firstText {
    font-size: 35px;
  }

  .Download_App .Download_App_content_holder .text_content .main_heading_holder .secondText {
    font-size: 35px;
  }
}
.PrivacyPolicy {
    margin-top: 3%;
    margin-bottom: 7%;
}

.PrivacyPolicy .border-heading {
    border-bottom: 1px solid #2E60FF;
}

.PrivacyPolicy .content .Point .sub-text {
    font-weight: 600;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.PrivacyPolicy .content .Point .text {
    margin-bottom: 0px;
}

.PrivacyPolicy h1.heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.PrivacyPolicy .sub-heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

@media(min-width:0px) and (max-width:767px) {
    .PrivacyPolicy .border-heading {
        display: none;
    }
    .PrivacyPolicy .content .Point .text {
      
        text-align: justify;
    }
    p.sub-text.mt-4 {
        text-align: justify;
    }
}
.step-sec {
    background: transparent linear-gradient(255deg, #C2BCFF 0%, #8E86E8 15%, #6D63DC 57%, #2A1FA2 100%) 0% 0% no-repeat padding-box;
    margin: 5% 0;
    padding: 38px 0px;
}

.step-sec .heading-holder h2 {
    color: #FFFFFF;
    opacity: 0.48;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.step-sec .step-card .img-holder .down-img {
    width: 32px;
    height: 50px;
}

.step-sec .step-card .img-holder .call-img {
    width: 52px;
    height: 52px;

}

.step-sec .step-card .img-holder .party-img {
    width: 56px;
    height: 57px;

}

.step-sec .step-card .heading-holder h3 {
    font-size: 30px;
    color: #FFF;
    font-family: "Naked Power W00 Heavy" !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.step-sec .step-card .text-holder p {
    color: white;
    max-width: 237px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
}

/* Media Query Started */
@media (min-width:0px) and (max-width:485px) {
    .step-sec .step-card .heading-holder h3 {
        font-size: 22px;
        text-align: center;
    }

    .step-sec .step-card .img-holder .call-img {
        width: 45px;
        height: 45px;
    }

    .step-sec .step-card .img-holder {
        text-align: center;
    }

    .step-sec .step-card .text-holder p {
        margin: auto;
    }

    .step-sec .step-card .img-holder .down-img {
        width: 29px;
        height: 44px;
    }
    .step-card {
        margin-bottom: 13%;
    }
}

@media (min-width:486px) and (max-width:575px) {
    .step-sec .step-card .heading-holder h3 {
        font-size: 24px;
        text-align: center;
    }

    .step-sec .step-card .img-holder .call-img {
        width: 45px;
        height: 45px;
    }

    .step-sec .step-card .img-holder .down-img {
        width: 29px;
        height: 44px;
    }
    .step-sec .step-card .img-holder {
        text-align: center;
    }
    .step-sec .step-card .text-holder p {
        margin: auto;
    } 
    .step-sec .step-card {
        margin-bottom: 10%;
    }
    .step-sec .text-holder {
        text-align: center;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .step-sec .step-card .heading-holder h3 {
        font-size: 26px;
    }

    .step-sec .step-card .img-holder .call-img {
        width: 45px;
        height: 45px;
    }

    .step-sec .step-card .img-holder .down-img {
        width: 29px;
        height: 44px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .step-sec .step-card .heading-holder h3 {
        font-size: 25px;
    }

    .step-sec .step-card .img-holder .call-img {
        width: 45px;
        height: 45px;
    }

    .step-sec .step-card .img-holder .down-img {
        width: 29px;
        height: 44px;
    }
}
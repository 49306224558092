.image-sec {
    background-image: url('../../../../public/assest/image/homepage/Scroll-Group.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 382px; */
    margin-top: 60%;
    margin-bottom: 5%;

}

.image-sec .Phone-img .iphone-img {
    width: 675px;
    height: 382px;
    object-fit: contain;
}

@media(min-width:0px) and (max-width:420px) {
    .image-sec .Phone-img .iphone-img {
        width: 293px;
        height: 193px;
    }
}

@media(min-width:421px) and (max-width:767px) {
    .image-sec .Phone-img .iphone-img {
        width: 350px;
        height: 246px;
    }

    .image-sec {
        margin-top: 73%;
    }
}

@media(min-width:0px) and (max-width:420px) {
    .image-sec {
        margin-top: 150%;
    }
}

@media(min-width:420px) and (max-width:486px) {
    .image-sec {
        margin-top: 120%;
    }
}

@media(min-width:486px) and (max-width:578px) {
    .image-sec {
        margin-top: 90%;
    }
}

@media(min-width:578px) and (max-width:767px) {
    .image-sec {
        margin-top: 73%;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .image-sec {
        margin-top: 75%;
    }
}



@media(min-width:1200px) and (max-width:1400px) {
    .image-sec {
        margin-top: 64%;
    }
}
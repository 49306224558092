.home-banner {
    position: relative;
    margin-bottom: 10%;
}

.home-banner .image-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}

.home-banner .bann-img {
    height: auto;
    width: 100%;
}

.home-banner .main-banner-sec {
    /* padding: 15% 0; */
    position: absolute;
    top: 172px;
    z-index: 6;
    left: 7%;

}

.home-banner .text-holder span {
    color: #6A21A6 !important;
    font-style: italic !important;
    font-size: 60px;
    font-weight: 800;
}

.home-banner .text-holder h1 {
    color: #6A21A6;
    font-style: italic;
    font-weight: 800 !important;
}

.home-banner .text-holder h2 {
    color: #000;
    font-weight: 800 !important;
}

.home-banner .border-line {
    border-bottom: 1px solid #6A21A6;
}

.home-banner .download-btn p {
    font-size: 15px;
    font-weight: 500;
}

.home-banner .download-btn .btn-down {
    border: none;
    background: transparent;
}

.home-banner .download-btn .google-img {

    height: 41px;
}

@media(min-width:0px) and (max-width:992px) {
    .home-banner .bann-img {
        height: 448px;

    }

    .home-banner .image-holder .image-overlay {
        position: absolute;
        width: 100%;
        height: 448px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #ffffffb0;
    }

    .home-banner .text-holder h1 {
        /* color: #FFF; */
        position: relative;
        z-index: 1;
    }

    .home-banner .text-holder h2 {
        /* color: #FFF; */
        position: relative;
        z-index: 1;
    }

    .home-banner .download-btn p {
        position: relative;
        z-index: 1;
    }

    .home-banner .download-btn .btn-down {
        position: relative;
        z-index: 1;
    }

    .home-banner .main-banner-sec {
        top: 66px;
        z-index: 6;
        left: 7%;
    }
}

@media(min-width:992px) and (max-width:1200px) {

    /* .home-banner {
        height: 527px;
    } */
    .home-banner .main-banner-sec {

        top: 117px;
        z-index: 6;
        left: 7%;
    }
}
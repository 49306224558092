/* WhoWeAre Css Started */
.WhoWeAre {
    margin-top: 5%;
    margin-bottom: 7%;
}

.WhoWeAre .Who-We-Are img.Banner-who {
    height: 486px;
    width: 100%;
    object-fit: contain;
}

.WhoWeAre .who-Content .main .points img.points-image {
    width: 12px;
    height: 12px;
}

.WhoWeAre .who-Content .main {
    display: flex;
}

.WhoWeAre .who-Content .main .text {
    margin: 4px 9px;

    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden; */
    font-weight: 500;
    /* -webkit-line-clamp: 1; */
}

.WhoWeAre .who-Content .border-content {
    width: 60%;
    border-bottom: 1px solid #0050ff;
}



.WhoWeAre .who-Content .Main {
    margin-top: 3%;
}

.WhoWeAre .doted-image {
    background-image: url("../../../public/assest/image/About/about\ us\ _\ 1.png");
    height: 530px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}

.WhoWeAre .who-Content {
    margin-top: 6%;
    padding-right: 10%;
}

.WhoWeAre .text {
    text-align: justify;
}

/* WhoWeAre Css End */

/* Media Query Started */

@media (min-width: 0px) and (max-width: 485px) {
    .WhoWeAre .Who-We-Are img.Banner-who {
        height: 219px;
    }

    .WhoWeAre .doted-image {
        background-image: none;
        height: auto;
    }

    .who-Content {
        margin-top: 0%;
    }

    .WhoWeAre .who-Content {
        margin-top: 4%;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .WhoWeAre .Who-We-Are img.Banner-who {
        height: 300px;
    }

    .WhoWeAre .doted-image {
        background-image: none;
        height: auto;
    }

    .who-Content {
        margin-top: 0%;
    }

    .WhoWeAre .who-Content {
        margin-top: 4%;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .WhoWeAre .Who-We-Are img.Banner-who {
        height: 340px;
    }

    .WhoWeAre .doted-image {
        background-image: none;
        height: auto;
    }

    .who-Content {
        margin-top: 0%;
    }

    .WhoWeAre .who-Content {
        margin-top: 4%;
    }

    .WhoWeAre {
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .WhoWeAre .doted-image {
        background-image: none;
        height: auto;
    }

    .who-Content {
        margin-top: 0%;
    }

    .Main {
        margin-top: 4%;
    }

    .WhoWeAre {
        margin-top: 5%;
        margin-bottom: 0%;
    }

    .WhoWeAre .who-Content {
        margin-top: 3%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .WhoWeAre .who-Content {
        margin-top: 3%;
    }

    .WhoWeAre {
        margin-bottom: 25%;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .WhoWeAre {
        margin-bottom: 20%;
    }
}

@media (min-width: 1400px) and (max-width: 1600px) {
    .WhoWeAre {
        margin-bottom: 14%;
    }
}
/* main Feature Css Started */

.main-features {
  margin: 6% 0;
}

.main-features .heading-holder h3 {
  font-weight: bold;
}

.main-features .heading-holder p {
  max-width: 348px;
}

.main-features .video-class-grid {
  object-fit: cover;
  height: 500px;
  width: 100%;
}

/* main Feature Css End */

@media(min-width:0px) and (max-width:360px) {
  .main-features .video-class-grid {
    height: 214px;
  }

  .main-features .heading-holder h3 {
    text-align: center;
  }

  .main-features .heading-holder p {
    text-align: center;
    max-width: unset;
  }
}

@media(min-width:360px) and (max-width:420px) {
  .main-features .video-class-grid {
    height: 254px;
  }

  .main-features .heading-holder h3 {
    text-align: center;
  }

  .main-features .heading-holder p {
    text-align: center;
    max-width: unset;
  }
}

@media(min-width:420px) and (max-width:486px) {
  .main-features .video-class-grid {
    height: 290px;
  }

  .main-features .heading-holder h3 {
    text-align: center;
  }

  .main-features .heading-holder p {
    text-align: center;
    max-width: unset;
  }
}

@media(min-width:486px) and (max-width:567px) {
  .main-features .video-class-grid {
    height: 384px;
  }
}

@media(min-width:567px) and (max-width:767px) {
  .main-features .video-class-grid {
    height: 384px;
  }

  .main-features .heading-holder h3 {
    text-align: center;
  }

  .main-features .heading-holder p {
    text-align: center;
    max-width: unset;
  }
}

@media(min-width:768px) and (max-width:992px) {
  .main-features .video-class-grid {
    height: 234px;
  }

  .main-features .heading-holder p {
    font-size: 13px;
    max-width: unset;
  }
}

@media(min-width:992px) and (max-width:1200px) {
  .main-features .video-class-grid {
    height: 325px;
  }

  .main-features .heading-holder p {
    font-size: 13px;
    max-width: unset;
  }
}

@media(min-width:1200px) and (max-width:1400px) {
  .main-features .video-class-grid {
    height: 388px;
  }


}
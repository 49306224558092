.contact-us {
  position: relative;
  height: 100vh;
  /* margin-bottom: 12%; */
}

.contact-us .gmap_canvas .gmap_iframe {
  z-index: 0 !important;
  position: relative !important;
}

.contact-us .contact_form_holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  padding-top: 85px;
  background: #ffffffdb;
}

.contact-us .contact_form_bg {
  background: none;
  width: 50%;
  right: 0px !important;
  display: flex;
  justify-content: end !important;
  left: 50%;
}

.contact-us .form-holder {
  padding: 25px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #0000002b;
  border-radius: 6px;
  position: relative;
}

.contact-us .form-holder .form_container {
  height: 310px;
  overflow: hidden;
  overflow-y: scroll;
}

.contact-us .form-holder .form_container::-webkit-scrollbar {
  width: 2px;
}

.contact-us .form-holder .form_container::-webkit-scrollbar:horizontal {
  height: 8px;
}

/* Track */
.contact-us .form-holder .form_container::-webkit-scrollbar-track {
  background: #e8e8e8;
}

.contact-us .form-holder .form_container::-webkit-scrollbar-track:horizontal {
  background: #e8e8e8;
}

/* Handle */
.contact-us .form-holder .form_container::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}

.contact-us .form-holder .form_container .scroller::-webkit-scrollbar-thumb:horizontal {
  background: #c7c7c7;
  border-radius: 5px;
}

.contact-us .heading-holder h2 {
  font-size: 50px;
  font-weight: 900;
  color: #000;
}

.contact-us .icon-holder .call-icon {
  font-size: 12px;
  background: #0f3768;
  color: #fff;
  padding: 7px 1px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
}

.contact-us .add-icon {
  width: 32px;
  height: 32px;
}

.contact-us .icon-holder .location-icon {
  width: 18px;
  height: 26px;
}

.contact-us .address-holder p {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.contact-us .map-text p {
  text-decoration: underline;
  cursor: pointer;
}

.contact-us .form-holder .left-arrow {
  /* font-size: 13px;
  background: #df4223;
  color: #fff;
  padding: 5px 5px;
  border-radius: 50px; */
  width: 38px;
  height: 24px;
  position: absolute;
  top: -14px;
  left: 0px;
  cursor: pointer;
}

.contact-us .form-holder .form-control {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #7b7b7b;
  margin-bottom: 8px;
}

.contact-us .form-holder .text-holder {
  height: auto;
}

.contact-us .form-holder .form-control:focus {
  box-shadow: none;
}

.contact-us .border-holder {
  border-bottom: 2px solid #70707080;
}
.contact-us .social-icon{
  display: flex;
}
.contact-us .social-icon .facebook-icon {
  width: 27px;
  height: 27px;
  cursor: pointer;
}

.map-section .gmap_iframe {
  width: 100% !important;
  height: 100vh !important;
}

.contact-us .btn-dark {
  background: #212121;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  padding: 5px 20px;
}


.contact-us .scale-down-hor-right {
  -webkit-animation: scale-down-hor-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-down-hor-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.contact-us .contact-text-holder {
  padding: 0px 8px 0px 47px;
}


.contact-us .btn-holder button.SubmitBtn {
  color: white;
  position: relative;
  background-color: #6760BF;
  border: none;
  font-size: 14px;
  border-radius: 5px;
  z-index: 1;
}


.contact-us .btn-holder button.SubmitBtn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #493FBC;
  transition: all 1s ease;
  border-radius: 5px;
  z-index: 1;
}


.contact-us .btn-holder button.SubmitBtn:hover:before {
  width: 100%;
  opacity: 0.1;
}

.contact-us .insta-icon {
  color: #fff;
  background-color: #473DBB;
  border-radius: 50px;
  padding: 6px;
}

@-webkit-keyframes scale-down-hor-right {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  100% {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

@keyframes scale-down-hor-right {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  100% {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

.contact-us .scale-up-hor-right {
  -webkit-animation: scale-up-hor-right 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-hor-right 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

@media (min-width: 0px) and (max-width: 421px) {
  /* .contact-us {
    margin-bottom: 45%;
  } */

  .contact-us .contact_form_bg {
    background: none;
    width: 72%;
    right: 0px !important;
    display: flex;
    justify-content: end !important;
    left: 28%;
  }

  .contact-us .address-holder p {
    font-size: 13px;

  }

  .contact-us .contact-text-holder {
    padding: 0px 0px 0px 0px;
  }
}

@media (min-width: 421px) and (max-width: 577px) {
  /* .contact-us {
    margin-bottom: 40%;
  } */

  .contact-us .contact_form_bg {
    background: none;
    width: 72%;
    right: 0px !important;
    display: flex;
    justify-content: end !important;
    left: 28%;
  }

  .contact-us .address-holder p {
    font-size: 13px;

  }

  .contact-us .contact-text-holder {
    padding: 0px 0px 0px 0px;
  }
}

@media (min-width: 578px) and (max-width: 767px) {
  /* .contact-us {
    margin-bottom: 35%;
  } */

  .contact-us .contact_form_bg {
    background: none;
    width: 72%;
    right: 0px !important;
    display: flex;
    justify-content: end !important;
    left: 28%;
  }

  .contact-us .address-holder p {
    font-size: 13px;

  }

  .contact-us .contact-text-holder {
    padding: 0px 0px 0px 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  /* .contact-us {
    margin-bottom: 28%;
  } */

  .contact-us .address-holder p {
    font-size: 13px;

  }

  .contact-us .contact-text-holder {
    padding: 0px 0px 0px 0px;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .contact-us .heading-holder h2 {
    font-size: 25px;
  }

  .contact-us .social-icon {
    margin-bottom: 5%;
  }

  .contact-us .contact_form_holder {
    height: 120vh;
  }

  .map-section .gmap_iframe {
    height: 120vh !important;
  }

  .contact-us {
    height: 120vh;
  }

  .contact-us .contact-text-holder {
    padding: 0px 0px 0px 0px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .contact-us .heading-holder h2 {
    font-size: 35px;
  }

  /* .contact-us {
    margin-bottom: 18%;
  } */

  .contact-us .contact-text-holder {
    padding: 0px 8px 0px 12px;
  }
}
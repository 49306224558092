.header-sec {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  position: sticky;
  top: 0px;
  z-index: 999;
}

.header-sec .headlogo {
  width: 136px;
  height: 41px;
}

.header-sec .nav-link {
  font-size: 18px;
  font-weight: 600;
  padding: 3px 5px !important;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 8px;
  color: #000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #DCDCDC;
  border-radius: 3px;
  position: relative;
}

.header-sec .nav-link:hover {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border: 0.5px solid #DCDCDC;

  /* border-radius: 3px; */
  /* border-bottom: 2px solid #6A21A6; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
}

/* .header-sec .nav-link.active .x-image {
    display: block;
} */

.header-sec .nav-link .border-bottom {
  opacity: 0;
  content: "";
  width: 0%;
  border-bottom: 2px solid #6A21A6 !important;
  position: absolute;
  bottom: 3px;
  left: 0;
  transition: 0.4s ease-in-out;

}

.header-sec .nav-link.active {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border: 0.5px solid #DCDCDC;
  border-radius: 3px;
  /* border-bottom: 2px solid #6A21A6; */
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.header-sec .nav-link.active .x-image {
  opacity: 1;
  margin-right: 0px !important;

}

.header-sec .nav-link.active .border-bottom {
  width: 100%;
  transition: 0.4s ease-in-out;
  border-bottom: 2px solid #6A21A6 !important;
  opacity: 1;
}

.header-sec .nav-link:hover .border-bottom {
  width: 100%;
  border-bottom: 2px solid #6A21A6 !important;
  opacity: 1;
  transition: 0.4s ease-in-out;
}

/* .header-sec .nav-link.active .x-image {
    opacity: 1;
    margin-right: 0px !important;
    width: 15px;
    height: 12px;
} */

.header-sec .nav-link .x-image {
  width: 15px;
  height: 12px;
  /* display: none; */
  opacity: 0;
  margin-right: -17px !important;
}

.header-sec .nav-link:hover .x-image {
  width: 15px;
  height: 12px;
  /* display: block; */
  opacity: 1;
  margin-right: 0px !important;
  transition: 0.4s ease-in-out;
}

.header-sec .contact-button .contact-btn {
  background: transparent linear-gradient(259deg, #f68dff 0%, #b03ed8 27%, #8833cb 72%, #55138c 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border: none;
  width: 150px;
  height: 38px;
}

.header-sec .navbar-toggler:focus {
  box-shadow: none;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/* Apply the slide-left animation */
.slide-left {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@media (min-width: 0px) and (max-width: 992px) {
  .header-sec .nav-link {
    margin-top: 13px;
    margin-left: 0px;
    margin-right: 12px;
    padding: 10px;
    width: 200px;
    font-size: 15px;
  }

  .header-sec .nav-link.active {
    justify-content: left;
  }

  .header-sec .headlogo {
    width: 100px;
    height: 33px;
  }

  .header-sec .contact-button .contact-btn {
    margin-top: 13px;
  }


}
.terms-condition {
    margin: 5% 0;
}

.terms-condition .heading-holder h1 {
    font-size: 25px;
    font-weight: bold;
}
.terms-condition .text-holder ul li {
    font-size: 14px;
    margin-bottom: 28px;
}
.Shipping-policy{
    margin-top: 5%;
    margin-bottom: 15%;
}
.Shipping-policy .heading-holder h1 {
    font-size: 25px;
    font-weight: bold;
}
.Shipping-policy .text-holder ul li {
    font-size: 14px;
    margin-bottom: 28px;
}
.cancelation-policy {
    margin-top: 5%;
    margin-bottom: 10%;  
}
.cancelation-policy .heading-holder h1 {
    font-size: 25px;
    font-weight: bold;
}
.cancelation-policy .text-holder ul li {
    font-size: 14px;
    margin-bottom: 28px;
}
@import url('https://fonts.googleapis.com/css2?family=Open Sans:wght@400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Italianno&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://db.onlinewebfonts.com/c/36a795d8166d494fea3a8b2454858341?family=Naked+Power+W00+Heavy');
@import url('https://db.onlinewebfonts.com/c/36a795d8166d494fea3a8b2454858341?family=Naked+Power+W00+Heavy');

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', 'sans-serif', 'SourceSansPro-Light.otf' !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.main-heading {
  font-size: 55px;
  color: white;
  font-family: "Naked Power W00 Heavy" !important;
}

.heading {
  font-size: 40px;
  color: #333333;
  font-family: "Naked Power W00 Heavy" !important;
}

.sub-heading {
  font-size: 25px;
  color: #333333;
  font-weight: 600;
  font-family: "Poppins" !important;
}

.sub-text {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  font-family: "Poppins" !important;
}

.text {
  font-size: 15px;
  color: #333333;
  font-family: "Poppins" !important;
}

.big-heading {
  font-size: 50px;
  font-weight: 900;
  color: #FFFFFF;
  font-family: "Poppins" !important;
}

@media (min-width:0px) and (max-width:991px) {
  .aos-animate {

    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;

    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;

    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}
}

@media (min-width:0px) and (max-width:485px) {
  .heading {
    font-size: 21px;
  }

  .sub-heading {
    font-size: 18px;
  }

  .sub-text {
    font-size: 14px;
  }

  .text {
    font-size: 12px;
  }

  .big-heading {
    font-size: 30px;
  }

  .main-heading {
    font-size: 25px;
  }
}

@media (min-width:486px) and (max-width:575px) {
  .heading {
    font-size: 36px;
  }

  .sub-heading {
    font-size: 20px;
  }

  .sub-text {
    font-size: 16px;
  }

  .text {
    font-size: 13px;
  }

  .big-heading {
    font-size: 35px;
  }

  .main-heading {
    font-size: 32px;
  }
}

@media (min-width:576px) and (max-width:767px) {
  .heading {
    font-size: 32px;
  }

  .sub-heading {
    font-size: 18px;
  }

  .sub-text {
    font-size: 16px;
  }

  .text {
    font-size: 14px;
  }

  .big-heading {
    font-size: 35px;
  }

  .main-heading {
    font-size: 34px;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .heading {
    font-size: 35px;
  }

  .sub-heading {
    font-size: 21px;
  }

  .text {
    font-size: 14px;
  }

  .big-heading {
    font-size: 40px;
  }

  .main-heading {
    font-size: 33px;
  }
  .sub-text {
    font-size: 16px;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .big-heading {
    font-size: 42px;
  }

  .main-heading {
    font-size: 38px;
  }
}
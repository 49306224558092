/* Meet Founder Css Started */

.Meet-Founders .Video .video {
    width: 686px;
    height: 386px;
}

.Meet-Founders .text-background {
    position: relative;
    width: 100%;
    height: 577px;
    background: transparent linear-gradient(240deg, #f68dff 0%, #b03ed8 27%, #8833cb 72%, #55138c 100%) 0% 0% no-repeat
        padding-box;
}

.Meet-Founders .Video {
    align-items: flex-end;
    right: 0px;
    top: 103px;
    position: absolute;
    left: 13px;
    z-index: 9;
}

.Meet-Founders .main {
    position: relative;
}

.Meet-Founders .text-background .content {
    position: absolute;
    top: 50%;
    left: 21%;
    transform: translate(0%, -49%);
}

.Meet-Founders .big-heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

/* Meet Founder Css End */

@media (min-width: 0px) and (max-width: 767px) {
    .Meet-Founders .Video {
        position: unset;
    }

    .Meet-Founders .Video .video {
        width: 100%;
        height: 219px;
    }

    .Meet-Founders .text-background {
        height: 259px;
    }

    .Meet-Founders .text-background .content {
        left: 14%;
    }
    .Meet-Founders .big-heading {
        -webkit-line-clamp: 4;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .Meet-Founders .Video .video {
        width: 331px;
        height: 306px;
    }

    .Meet-Founders .Video {
        top: 118px;
    }

    .Meet-Founders .text-background .content {
        left: 18%;
    }
    .Meet-Founders .big-heading {
        -webkit-line-clamp: 4;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .Meet-Founders .Video .video {
        width: 455px;
        height: 306px;
    }

    .Meet-Founders .Video {
        top: 118px;
    }

    .Meet-Founders .text-background .content {
        left: 18%;
    }
    .Meet-Founders .big-heading {
        -webkit-line-clamp: 3;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .Meet-Founders .Video .video {
        width: 567px;
        height: 386px;
    }
    .Meet-Founders .big-heading {
        -webkit-line-clamp: 3;
    }
}

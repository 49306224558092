/* AboutTimer Css Started */
.AboutTimer {
  margin: 7%;
  margin-bottom: 7%;
}

.AboutTimer .About {
  background-image: url("../../../public/assest/image/About/timer-banner.png");
  height: 326px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.AboutTimer .contact-background {
  height: 148px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  margin-top: -7%;
}

.AboutTimer .About .total-holder .sub-text {
  color: white;
}

.AboutTimer .main-timer.row {
  padding-top: 10%;
}

.main_feature_content {
  margin-top: -4%;
}

.AboutTimer .main_feature_content .meet_text_div {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 35px 0px;
  opacity: 1;
  margin-top: -45px;
}

.AboutTimer .main_feature_content .cotntact_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AboutTimer .main_feature_content .cotntact_div-two {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AboutTimer .main_feature_content .icon_div_sectiom {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;

}

.AboutTimer .main_feature_content .border_contact_div {
  border-style: solid;
  border-image: linear-gradient(175deg, #C2BCFF 0%, #8E86E8 15%, #4e42d9 57%, #2A1FA2 100%) 1;
  border-left: 4px solid;
  border-right: none;
  border-top: none;
  border-bottom: none;
  margin: 0px -23px;
}

.AboutTimer .main_feature_content .abcc {
  position: relative;
  z-index: 1;
}

.AboutTimer .main_feature_content .bordercircle {
  position: absolute;
  z-index: -1;
  width: 37px;
  height: 74px;
  top: -5px;
  left: -5px;
  background-color: #655BD5;
  border-bottom-left-radius: 110px;
  border-top-left-radius: 110px;
  border: 10px solid #655BD5;
  border-bottom: 0;
}

.AboutTimer .main_feature_content p.sub-text {
  font-weight: 600;
}

.AboutTimer .fa-envelop {
  color: #493FBC;
}

/* .main_feature_content {
  margin-top: -3%;
} */


/* AboutTimer Css End */

@media (min-width: 0px) and (max-width: 485px) {
  .AboutTimer .About {
    height: auto;
    background-size: cover;
  }

  .AboutTimer .main_feature_content .border_contact_div {
    border-left: none;
    margin-left: 0px;
  }

  .AboutTimer .main_feature_content .cotntact_div {
    padding-bottom: 33px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    text-align: -webkit-center;
  }

  .AboutTimer .main-timer.row {
    padding-top: 2%;
  }

  .AboutTimer .main_feature_content .meet_text_div {
    margin-top: 2px;
  }

}

@media (min-width: 486px) and (max-width:767px) {
  .AboutTimer .About {
    height: auto;
    background-size: cover;
  }

  .AboutTimer .main_feature_content .border_contact_div {
    border-left: none;
    margin-left: 8px;
  }

  .AboutTimer .main_feature_content .cotntact_div {
    padding-bottom: 33px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    text-align: -webkit-center;
  }

  .AboutTimer .main-timer.row {
    padding-top: 2%;
  }

  .AboutTimer .main_feature_content .meet_text_div {
    margin-top: 15px;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .AboutTimer .main-timer.row {
    padding-top: 18%;
  }

  .AboutTimer .main_feature_content .border_contact_div {
    border-left: 3px solid #655BD5;
    margin: 0px -20px;
  }

  .AboutTimer {
    margin-top: 2%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .AboutTimer .main-timer.row {
    padding-top: 14%;
  }


  .AboutTimer .main_feature_content .meet_text_div {
    margin-top: -75px;
  }
}
.footer {
    background: transparent linear-gradient(101deg, #E7E5FF 0%, #B2ACF8 28%, #6D63DC 57%, #2A1FA2 100%) 0% 0% no-repeat padding-box;
    position: relative;
    height: 333px;
    padding-top: 75px;
    padding-bottom: 46px;
}

.footer .x-image {
    position: absolute;
    top: 0px;
    right: 0;
}

.footer .x-image-foot {
    width: 323px;
    height: 331px;
}

.footer .headlogo {
    width: 136px;
    height: 41px;
}

.footer .headimg-holder h2 {
    font-size: 20px;
    text-decoration: underline;
    color: #FFF;
}

.footer .link-heading {
    margin-top: 32px;
    /* display: flex; */
}

.footer .link-heading p {
    font-size: 15px;
    color: #FFF;
    margin-bottom: 1%;
}

.footer .locate-icon {
    width: 18px;
    height: 18px;
}

.footer .address-holder p {
    font-size: 14px;
    color: #FFF;
}

.footer .social-icon {
    display: flex;
}

.footer .social-icon .icon-logo {
    width: 30px;
    height: 30px;
    margin-right: 14px;
}

/* .footer .circle-holder{
    background-color: #FFF;
    border-radius: 50px;
    padding: 10px;
} */
.footer .insta-icon {
    color: #473DBB;
    background-color: #FFF;
    border-radius: 50px;
    padding: 8px;
    z-index: 1;
    position: relative;
}

@media(min-width:0px) and (max-width:992px) {
    .footer .headlogo {
        width: 100px;
        height: 33px;
    }

    .footer {
        padding-top: 37px;
        height: auto;
    }

    .footer .link-heading {
        margin-top: 19px;
    }

    .footer .headimg-holder h2 {
        font-size: 18px;
        margin-top: 8px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .footer .social-icon .icon-logo {
        margin-right: 4px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .footer .social-icon .icon-logo {
        margin-right: 9px;
    }
}
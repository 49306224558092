/* About Banner css Started */

.About-Banner .About-img {
  position: relative;
}
.About-Banner .About-img img.Banner {
  height: 500px;
  width: 100%;
  object-fit: cover;
}
.About-Banner .banner-content {
  position: absolute;
  bottom: 22%;
  left: 11%;
}
.About-Banner .main-heading {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
/* About Banner css End */

@media (min-width: 0px) and (max-width: 320px) {
  .About-Banner .About-img img.Banner {
    height: 184px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .About-Banner .About-img img.Banner {
    height: 184px;
  }
}

@media (min-width: 486px) and (max-width: 576px) {
  .About-Banner .About-img img.Banner {
    height: 200px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .About-Banner .About-img img.Banner {
    height: 300px;
  }
}


@media (min-width: 768px) and (max-width: 991px) {
  .About-Banner .About-img img.Banner {
    height: 350px;
  }
}


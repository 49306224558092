/* WhyMeetX Css Started */

.WhyMeetX {
    background-image: url("../../../public/assest/image/homepage/Meetx.png");
    height: 711px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.WhyMeetX .heading {
    text-align: center;
}

.WhyMeetX .image .meet-x-image {
    width: 451px;
    height: 451px;
    object-fit: contain;
}
.WhyMeetX .Why-MeetX-Content .sub-heading {
    font-weight: bold;
}
.WhyMeetX .Why-MeetX-Content .paragraph-content .text {
    font-weight: 500;
}
.WhyMeetX .Why-MeetX-Content {
    margin-top: 7%;
}
.WhyMeetX .Why-MeetX-Content .paragraph-content {
    margin-top: 11%;
}
.WhyMeetX .Why-MeetX-Content .Read-More button.btn.btn-read {
    color: white;
    padding: 6px 26px;
    background: transparent linear-gradient(259deg, #f68dff 0%, #b03ed8 27%, #8833cb 72%, #55138c 100%) 0% 0% no-repeat
        padding-box;
    border-radius: 5px;
}
.WhyMeetX .Why-MeetX-Content .Read-More {
    margin-top: 8%;
}
/* WhyMeetX Css End */

/* Media Query Started */

@media (min-width: 0px) and (max-width: 485px) {
    .WhyMeetX .image img.meet-x-image {
        width: 100%;
        height: 328px;
    }
    .WhyMeetX {
        height: auto;
    }
    .WhyMeetX .Why-MeetX-Content .paragraph-content {
        margin-top: 5%;
    }
    .WhyMeetX .Why-MeetX-Content .Read-More {
        margin-top: 4%;
        text-align: center;
    }
}
@media (min-width: 486px) and (max-width: 991px) {
    .WhyMeetX .image img.meet-x-image {
        width: 451px;
        height: 451px;
    }
    .WhyMeetX {
        height: auto;
    }
    .WhyMeetX .Why-MeetX-Content .paragraph-content {
        margin-top: 5%;
    }
    .image {
        text-align: center;
    }
    .WhyMeetX .Why-MeetX-Content .Read-More {
        margin-top: 4%;
    }
}
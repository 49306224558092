/* Meet Founder Css Started */

.Meet-visionaries .heading {
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.Meet-visionaries .main-vision .vision-imag img.vision-man {
  width: 100%;
  height: 372px;
  border-radius: 15px;
  object-fit: cover;
}

.Meet-visionaries .main-vision .overlay-one {
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 73%, #000000 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 15px 15px;
  height: 127px;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Meet-visionaries .main-vision {
  position: relative;
  margin-top: 5%;
  margin-bottom: 10%;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.Meet-visionaries .main-vision .content-card {
  position: absolute;
  bottom: 14px;
  /* left: 36px; */
  width: 100%;
  padding-left: 36px;
}

.Meet-visionaries .main-vision .content-card .Name {
  display: flex;
  margin-left: 10px;
  flex-wrap: nowrap;
  justify-content: center;
}

.Meet-visionaries .main-vision .content-card .Name .border-name {
  position: relative;
  display: block;
  overflow: hidden;
  margin-right: 10px;
  margin-top: 10px;
  height: 45px;
}

.Meet-visionaries .main-vision:hover .content-card .Name .name-content .sub-heading {
  margin-bottom: 0px;
  color: white;
}

.Meet-visionaries .main-vision:hover .content-card .Name .name-content .text {
  color: white;
}

.Meet-visionaries .main-vision .content-card .Name .name-content .sub-heading {
  margin-bottom: 0px;
  color: white;
}

.Meet-visionaries .main-vision .content-card .Name .name-content .text {
  color: white;
}

.Meet-visionaries .main-vision .content-card .social-icons .circle {
  margin: 0px 5px;
  padding: 1px 5px;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.Meet-visionaries .main-vision .content-card .social .social-icons {
  display: flex;
  color: #493fbc;
  margin-left: 15px;
}

.Meet-visionaries .main-vision .content-card .social {
  display: none;
  transition: display 0.8s ease;
}

.Meet-visionaries .main-vision:hover .content-card .social {
  display: block;
  margin-top: 12px;
  margin-bottom: 8px;
}

.Meet-visionaries .main-vision {
  position: relative;
}

.Meet-visionaries .Content-vision .sub-heading {
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

section.Meet-visionaries {
  margin-top: 4%;
  margin-bottom: 8%;
}

.Meet-visionaries .main-vision .content-card .Name {
  position: relative;
}

.Meet-visionaries .main-vision:hover .content-card:before {
  border: 0px solid #e277f4;
  width: 100%;
  background: transparent linear-gradient(90deg, #e277f4 0%, #713c7a8f 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.Meet-visionaries .main-vision .content-card .textssss .sub-text {
  color: WHITE;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  position: relative;
}

.Name:active {
  transform: scale(0.96);
}

.Meet-visionaries .main-vision .textssss {
  position: relative;
  color: #fff;
  /* border: 1px solid #fff; */
  padding-left: 20px;
  transform: 0.9s;
}

.Meet-visionaries .main-vision .textssss p {
  z-index: 9;
}

.Meet-visionaries .main-vision .textssss .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 12px;
  z-index: 1;

  background: transparent linear-gradient(90deg, #e277f4 0%, #713c7a8f 100%) 0% 0% no-repeat padding-box;

  transition: 0.5s;
}

.Meet-visionaries .main-vision:hover .textssss {
  padding-top: 10px;
  padding-bottom: 10px;
}

.Meet-visionaries .main-vision:hover .textssss .overlay {
  width: 100%;
}

.Meet-visionaries .main-vision:hover .textssss p {
  z-index: 99;
}

@media (min-width: 0px) and (max-width: 991px) {
  .Meet-visionaries .text {
    text-align: justify;
  }
}
.experience-app {
    margin: 5% 0;
}

.experience-app .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 14px;
}

.experience-app .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 238px !important;
    background-color: #FFF;
    /* box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2); */
    filter: blur(1.5px);
}

.experience-app .swiper-slide-active {
    filter: blur(0px);

    /* background-color: #FFF; */
}

.experience-app .swiper-slide-active .bg-img {
    border-radius: 28px;
}

.experience-app .swiper-slide img {
    display: block;
    width: 100%;
}

.experience-app .orange-circle .bg-img {
    /* height: 553px; */
    width: 100%;
}

.experience-app .mobile-screen {
    position: absolute;
    top: 50%;
    left: 51%;
    transform: translate(-51%, -50%);
    z-index: 1;
}

.experience-app .mobile-screen .mobile-img {
    width: 340px;
    height: 578px;
}

.experience-app .main-slider {
    position: relative;
}

.experience-app .main-slider .arrow-back,
.experience-app .main-slider .arrow-next {
    cursor: pointer;
}


.experience-app .main-slider .arrow-back {
    content: url("../../../public/assest/image/icon/next.png");
    position: absolute;
    bottom: 0px;
    left: 50%;
    z-index: 2;
    cursor: pointer;
    display: block;
}


.experience-app .main-slider .arrow-next {
    content: url("../../../public/assest/image/icon/prev.png");
    position: absolute;
    bottom: 0px;
    right: 50%;
    z-index: 1;
    cursor: pointer;
    display: block;
}

@media(min-width:0px) and (max-width:768px) {
    .experience-app .mobile-screen {
        transform: translate(-49%, -50%);

    }
}